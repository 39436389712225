<template>
    <div ref="body" class="bg-white overflow-hidden">
        <div class="grid grid-cols-11">
            <div ref="content" class="col-span-4 border">
                <div class="border-b px-5 py-3">
                    <input v-model="search" type="text" class="form-control" placeholder="Search customer name">
                </div>
                <ul  class="h-full overflow-scroll">
                    <li v-for="(payment, i) in paymentFilter" :key="i" @click="onSelect(payment)" class="border-b px-5 py-3 relative cursor-pointer hover:bg-gray-100">
                        <div v-if="payment.isSelected" class="absolute w-2 bg-teal-500 h-full top-0 left-0"></div>
                        <div class="flex items-center justify-between font-medium">
                            <div>
                                <div>{{getDatePaid(payment.createdAt)}} <span class="ml-3" :class="[payment.paymentStatus == 'In Payment' ? 'text-green-500':'text-red-500']">{{payment.paymentStatus}}</span></div>
                            </div>
                            <div class="font-semibold">
                                Rp. {{Intl.NumberFormat('en-US').format(payment.grandTotal)}}
                            </div>
                        </div>
                        <div class="truncate">
                            INVOICE {{payment.invoiceNo}} - {{payment.billTo.displayName}}
                        </div>
                    </li>
                </ul>
            </div>
            <div v-if="form" class="col-span-7 p-5">
                <div class="grid grid-cols-3 mb-5">
                    <div>
                        <div class="text-base font-medium">{{form.invoiceNo}}</div>
                        <div class="text-gray-400">CUSTOMER INVOICE #</div>
                    </div>
                    <div>
                        <div class="text-base font-medium">{{getDate(form.createdAt)}}</div>
                        <div class="text-gray-400">DATE CREATED</div>
                    </div>
                    <div>
                        <div class="text-base font-medium">Rp {{Intl.NumberFormat('en-US').format(form.grandTotal)}}</div>
                        <div class="text-gray-400">TOTAL</div>
                    </div>
                </div>
                <div class="grid grid-cols-3 mb-2 border-b pb-1">
                    <div>
                        <div>Customer</div>
                        <div class="font-medium">{{form.billTo.customer}}</div>
                    </div>
                    <div>
                        <div>Attn</div>
                        <div class="font-medium">{{form.billTo.ttn}}</div>
                    </div>
                    <div>
                        <div>User</div>
                        <div class="font-medium">{{form.user}}</div>
                    </div>
                </div>
                <ul>
                    <li v-for="(payment, i) in form.payments" :key="i" class="border border-dashed border-l-0 border-r-0 py-2">
                        <div class="flex justify-between">
                            <div>
                                <div>{{payment.bank.name}}</div>
                                <div>{{payment.bank.accountNumber}}</div>
                                <div>{{payment.bank.accountName}}</div>
                                <div class="text-gray-400 italic">User: {{payment.user}}</div>
                            </div>
                            <div>
                                <div class="italic mb-2"> <span class="mr-2"><i :class="[payment.isValidate ? 'icon-checkmark-circle text-green-500': 'icon-notification2']"></i></span> Paid On {{getDate(payment.paymentDate)}}</div>
                                <div v-if="!payment.isValidate">
                                    <div>
                                        <autonumeric
                                            v-model="payment.paid" 
                                            class="border-b text-base text-right w-full focus:outline-none bg-gray-50"
                                            :options="{modifyValueOnWheel : false}"
                                        >
                                        </autonumeric>
                                    </div>
                                    <div class="mt-2">
                                        <button @click="onValidate(payment)" class="bg-teal-500 w-full py-2 rounded-sm text-teal-50 hover:bg-teal-600">VALIDATE</button>
                                    </div>

                                </div>
                                <div v-else>
                                    <div class="text-base text-right">{{Intl.NumberFormat('en-US').format(payment.amount)}}</div>
                                    <div class="text-right italic">Validate by {{payment.validBy}}</div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import {moment} from '../../../moment'
import Autonumeric from '../../../components/Autonumeric.vue'
export default {
    components: {Autonumeric},
    data () {
        return {
            payments: [],
            search: '',
            form: ''
        }
    },
    mounted () {
        let s = window.screen.height
        let content = s - 170
        let b = s - 170
        this.$refs.body.style.height = b+'px'
        this.$refs.content.style.height = content+'px'
        this.getData()
    },
    computed: {
        paymentFilter () {
            return this.payments.filter((obj) => {
                return obj.billTo.displayName.toLowerCase().includes(this.search.toLowerCase())
            })
        },
        // difference () {

        // }
    },
    methods: {
        getData () {
            this.payments = []
            axios.get('/reconciliate/customer')
            .then(res => {
                this.payments = res.data
            })
        },
        getDatePaid (d) {
            const date = new Date(d)
            let dd = date.getDate()
            let mm = date.getMonth() + 1
            let yy = date.getFullYear()
            dd = checkTime(dd)
            mm = checkTime(mm)
            function checkTime (i) {
                if(i < 10) {
                    i = `0${i}`
                }
                return i
            }
            return `${dd}/${mm}/${yy}`
        },
        getDate (d) {
            return moment (d)
        },
        onSelect (p) {
            this.form = p
            for (let i in this.payments) {
                if(this.payments[i]._id == p._id) {
                    this.payments[i].isSelected = true
                } else {
                    this.payments[i].isSelected = false
                }
            }
        },
        onValidate (payment) {
            axios.post('/reconciliate/customer', payment)
            .then(() => {
                this.getData()
                this.form = ''
            })
        }
    }
}
</script>